import styled from 'styled-components';

export const UserList = styled.ul``;

export const User = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
`;
