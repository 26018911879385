export enum AnnouncementStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  AWAIT = 'await',
  REJECTED = 'rejected',
}

export enum LandCategoryEnum {
  AGRICULTURAL = '003001000000',
  URBAN = '003002000000',
  INDUSTRIAL = '003003000000',
}
