import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ReactDOM from 'react-dom/client';
import { CustomProvider } from 'rsuite';
import ruRu from 'rsuite/locales/ru_RU';

import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <CookiesProvider>
        <CustomProvider locale={ruRu}>
          <App />
        </CustomProvider>
      </CookiesProvider>
    </Provider>
  </BrowserRouter>,
);

reportWebVitals();
