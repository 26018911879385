import React from 'react';

import warningIcon from '../../assets/warning_icon.png';

const WarningIcon = ({ title }: { title: string }) => {
  return (
    <div>
      <img src={warningIcon} alt='иконка предупреждения' title={title} />
    </div>
  );
};

export default WarningIcon;
