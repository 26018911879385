/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';

import checkedAdIcon from '../../assets/checked-ad-icon.png';
import { useAppSelector } from '../../redux/hooks';
import { Ad } from '../../redux/services/ads/interface';
import { selectFilterAds } from '../../redux/slices/filtersAdsSlice';
import { ADS_ROUTE, myDomain } from '../../utils/consts';
import formateAdDate from '../../utils/funcs/format-ad-date';
import priceByAreaUnitFilter from '../../utils/funcs/price-by-area-unit-filter';
import { replaceValueWithSpaces } from '../../utils/funcs/replace-value';
import WarningIcon from '../warning-icon';
import AdCardSliderPhotos from './ad-card-slider-photos';
import * as S from './styles';

const AdCard = ({
  address,
  date_published,
  description,
  domain,
  id,
  is_checked,
  isInclude,
  photos,
  price,
  title,
  unit_price,
  url,
}: Ad) => {
  const isBankZemel = domain === myDomain ? true : false;

  const { areaUnit } = useAppSelector(selectFilterAds);

  const pricePerArea = priceByAreaUnitFilter(areaUnit, unit_price);

  if (!photos) {
    return null;
  }

  return (
    <S.CardWrapper>
      <div>
        <AdCardSliderPhotos photos={photos} title={title} domain={domain} />
      </div>
      <Link to={`${ADS_ROUTE}/${id}`} target='_blank'>
        <S.CardInfo>
          <S.CardTitleWrapper>
            <S.CardTitle>{title}</S.CardTitle>
            {isInclude === false && (
              <WarningIcon title='Возможно, объявление имеет неверное соотношение цены к площади' />
            )}
            {is_checked && (
              <S.AdCheckedIcon
                src={checkedAdIcon}
                title='Проверено модератором'
                isChecked={is_checked}
              />
            )}
          </S.CardTitleWrapper>

          <S.CardAddress>{address}</S.CardAddress>
          <S.CardDatePublishedWrapper>
            {date_published && (
              <S.CardDatePublished>
                Опубликовано: {formateAdDate(date_published)}
              </S.CardDatePublished>
            )}
          </S.CardDatePublishedWrapper>
          <S.CardPrice>{replaceValueWithSpaces(price.toString())} ₽</S.CardPrice>
          <S.CardPricePerArea>{pricePerArea}</S.CardPricePerArea>
          <S.CardDescription>{description || 'У объявления нет описания'}</S.CardDescription>
          {!isBankZemel && (
            <S.CardDomain to={url} target='_blank' onClick={(e) => e.stopPropagation()}>
              Источник
            </S.CardDomain>
          )}
        </S.CardInfo>
      </Link>
    </S.CardWrapper>
  );
};

export default AdCard;
