/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';

import * as XLSX from 'xlsx';

import { Ad } from '../../redux/services/ads/interface';
import CustomButton from '../custom-button';
import DownloadAdsXlsxProps from './interface';
import * as S from './styles';

const landCategory = {
  agricultural: 'с/х',
  industrial: 'промназначения',
  urban: 'населенные пункты',
};

const landUse = {
  farming: 'Фермерское хозяйство',
  gardening: 'СНТ',
  izhs: 'ИЖС',
  lph: 'ЛПХ',
  // eslint-disable-next-line camelcase
  suburban_construction: 'ДНП',
};

const propsForDelete: Array<keyof Ad> = [
  'id',
  'photos',
  'land_class',
  'land_plot_title',
  'railway_line',
  'asphalt_pavement',
  'electricity',
  'gas',
  'water_supply',
  'sewage',
  'highway_proximity',
  'flat_land_level',
  'is_checked',
  'domain',
  'date_checked',
  'region_kladr_id',
  'type_of_use',
  'cultivated_crop',
  'irrigation',
  'survey',
  'rent_period',
  'unit_price',
  'status',
  'isInclude',
];

const headersSheet = [
  'Заголовок объявления',
  'Описание',
  'Площадь, кв.м',
  'Адрес',
  'Цена',
  'Категория земель',
  'Землепользование',
  'Право собственности',
  'Телефон',
  'Координата широты объекта',
  'Координата долготы объекта',
  'Дата публикации',
  'Дата обновления',
  'Имя владельца',
  'Кадастровый номер',
  'Ссылка на источник',
];

const DownloadAdsXlsx = ({
  isLoading,
  isSuccess,
  listAnnouncement,
  page,
}: DownloadAdsXlsxProps) => {
  const downloadxls = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: Ad[] | undefined,
  ) => {
    e.preventDefault();
    if (data) {
      const dataCopy: Ad[] = JSON.parse(JSON.stringify(data));
      const dataForSheet = dataCopy.map((el) => {
        propsForDelete.forEach((prop) => delete el[prop]);

        switch (el.land_category) {
          case 'agricultural':
            el['land_category'] = landCategory.agricultural;
            break;
          case 'industrial':
            el['land_category'] = landCategory.industrial;
            break;
          case 'urban':
            el['land_category'] = landCategory.urban;
            break;
        }

        switch (el.land_use) {
          case 'izhs':
            el['land_use'] = landUse.izhs;
            break;
          case 'lph':
            el['land_use'] = landUse.lph;
            break;
          case 'suburban_construction':
            el['land_use'] = landUse['suburban_construction'];
            break;
          case 'gardening':
            el['land_use'] = landUse.gardening;
            break;
          case 'farming':
            el['land_use'] = landUse.farming;
            break;
        }

        switch (el['is_rent']) {
          case false:
            el['is_rent'] = 'В собственности';
            break;
          case true:
            el['is_rent'] = 'В аренде';
            break;
        }

        return el;
      });

      const ws = XLSX.utils.json_to_sheet(dataForSheet);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Объявления');

      XLSX.utils.sheet_add_aoa(ws, [headersSheet], { origin: 'A1' });

      if (!ws['!cols']) ws['!cols'] = [];

      for (let i = 0; i < 16; i++) {
        ws['!cols'][i] = { wch: 40 };
      }

      XLSX.writeFile(wb, `Объявления.Cтраница ${page}.xlsx`);
    }
  };

  console.log(listAnnouncement);

  return (
    <S.DownloadCSVBtnWrapper>
      <CustomButton
        disabled={isLoading}
        variant='outlined'
        onClick={(e) => {
          isSuccess && downloadxls(e, listAnnouncement);
        }}
      >
        Выгрузить объявления в xlsx
      </CustomButton>
    </S.DownloadCSVBtnWrapper>
  );
};

export default DownloadAdsXlsx;
