import React, { useState } from 'react';

import ProtectedPage from '../../components/protected-page-ads';
import RequestAdsCardList from '../../components/request-ads-card-list';
import { useAppSelector } from '../../redux/hooks';
import { useGetRequestAdsQuery } from '../../redux/services/request-ads/requestAdsApi';
import { selectUser } from '../../redux/slices/userSlice';
import { StyledContainer, StyledSection } from '../../styles/common-styled-components/styles';

const RequestAds = () => {
  const LIMIT: number = 20;

  const [page, setPage] = useState<string | number>(1);

  const getRequestAdsQuery = useGetRequestAdsQuery({
    limit: LIMIT,
    page: Number(page),
    userId: undefined,
  });

  const { isAuth } = useAppSelector(selectUser);

  if (!isAuth) {
    return <ProtectedPage />;
  }

  return (
    <StyledSection>
      <StyledContainer>
        <h1>Запросы на покупку земельных участков</h1>
        <RequestAdsCardList {...getRequestAdsQuery} limit={LIMIT} page={page} setPage={setPage} />
      </StyledContainer>
    </StyledSection>
  );
};

export default RequestAds;
