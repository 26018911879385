import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  border: 1px solid #b9c2c8;
  border-radius: 5px;
  overflow: hidden;
`;

export const SearchBar = styled.input`
  border: none;
  padding: 10px 10px;
  width: 100%;
`;
