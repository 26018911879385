import styled from 'styled-components';

export const FormSearchItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const PaddingWrapper = styled.div`
  padding: 0 20px 20px;
`;
