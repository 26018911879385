/* eslint-disable camelcase */
export const regions = [
  {
    federal_district: 'Южный',
    fias_id: 'd8327a56-80de-4df2-815c-4f6ab1224c50',
    geoname_code: 'RU.01',
    geoname_id: 584222,
    geoname_name: 'Adygeya Republic',
    iso_code: 'RU-AD',
    kladr_id: '0100000000000',
    name: 'Адыгея',
    name_with_type: 'Республика Адыгея',
    okato: 79000000000,
    oktmo: 79000000,
    postal_code: 385000,
    tax_office: '0100',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '6f2cbfd8-692a-4ee4-9b16-067210bde3fc',
    geoname_code: 'RU.08',
    geoname_id: 578853,
    geoname_name: 'Bashkortostan Republic',
    iso_code: 'RU-BA',
    kladr_id: '0200000000000',
    name: 'Башкортостан',
    name_with_type: 'Республика Башкортостан',
    okato: 80000000000,
    oktmo: 80000000,
    postal_code: 452000,
    tax_office: '0200',
    timezone: 'UTC+5',
    type: 'Респ',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'a84ebed3-153d-4ba9-8532-8bdf879e1f5a',
    geoname_code: 'RU.11',
    geoname_id: 2050915,
    geoname_name: 'Buryatiya Republic',
    iso_code: 'RU-BU',
    kladr_id: '0300000000000',
    name: 'Бурятия',
    name_with_type: 'Республика Бурятия',
    okato: 81000000000,
    oktmo: 81000000,
    postal_code: 671000,
    tax_office: '0300',
    timezone: 'UTC+8',
    type: 'Респ',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '5c48611f-5de6-4771-9695-7e36a4e7529d',
    geoname_code: 'RU.03',
    geoname_id: 1506272,
    geoname_name: 'Altai',
    iso_code: 'RU-AL',
    kladr_id: '0400000000000',
    name: 'Алтай',
    name_with_type: 'Республика Алтай',
    okato: 84000000000,
    oktmo: 84000000,
    postal_code: 649000,
    tax_office: '0400',
    timezone: 'UTC+7',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: '0bb7fa19-736d-49cf-ad0e-9774c4dae09b',
    geoname_code: 'RU.17',
    geoname_id: 567293,
    geoname_name: 'Dagestan',
    iso_code: 'RU-DA',
    kladr_id: '0500000000000',
    name: 'Дагестан',
    name_with_type: 'Республика Дагестан',
    okato: 82000000000,
    oktmo: 82000000,
    postal_code: 368000,
    tax_office: '0500',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: 'b2d8cd20-cabc-4deb-afad-f3c4b4d55821',
    geoname_code: 'RU.19',
    geoname_id: 556349,
    geoname_name: 'Ingushetiya Republic',
    iso_code: 'RU-IN',
    kladr_id: '0600000000000',
    name: 'Ингушетия',
    name_with_type: 'Республика Ингушетия',
    okato: 26000000000,
    oktmo: 26000000,
    postal_code: 386000,
    tax_office: '0600',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: '1781f74e-be4a-4697-9c6b-493057c94818',
    geoname_code: 'RU.22',
    geoname_id: 554667,
    geoname_name: 'Kabardino-Balkariya Republic',
    iso_code: 'RU-KB',
    kladr_id: '0700000000000',
    name: 'Кабардино-Балкарская',
    name_with_type: 'Кабардино-Балкарская Респ',
    okato: 83000000000,
    oktmo: 83000000,
    postal_code: '',
    tax_office: '0700',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Южный',
    fias_id: '491cde9d-9d76-4591-ab46-ea93c079e686',
    geoname_code: 'RU.24',
    geoname_id: 553972,
    geoname_name: 'Kalmykiya Republic',
    iso_code: 'RU-KL',
    kladr_id: '0800000000000',
    name: 'Калмыкия',
    name_with_type: 'Республика Калмыкия',
    okato: 85000000000,
    oktmo: 85000000,
    postal_code: '',
    tax_office: '0800',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: '61b95807-388a-4cb1-9bee-889f7cf811c8',
    geoname_code: 'RU.27',
    geoname_id: 552927,
    geoname_name: 'Karachayevo-Cherkesiya Republic',
    iso_code: 'RU-KC',
    kladr_id: '0900000000000',
    name: 'Карачаево-Черкесская',
    name_with_type: 'Карачаево-Черкесская Респ',
    okato: 91000000000,
    oktmo: 91000000,
    postal_code: '',
    tax_office: '0900',
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '248d8071-06e1-425e-a1cf-d1ff4c4a14a8',
    geoname_code: 'RU.28',
    geoname_id: 552548,
    geoname_name: 'Karelia',
    iso_code: 'RU-KR',
    kladr_id: 1000000000000,
    name: 'Карелия',
    name_with_type: 'Республика Карелия',
    okato: 86000000000,
    oktmo: 86000000,
    postal_code: '',
    tax_office: 1000,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: 'c20180d9-ad9c-46d1-9eff-d60bc424592a',
    geoname_code: 'RU.34',
    geoname_id: 545854,
    geoname_name: 'Komi',
    iso_code: 'RU-KO',
    kladr_id: 1100000000000,
    name: 'Коми',
    name_with_type: 'Республика Коми',
    okato: 87000000000,
    oktmo: 87000000,
    postal_code: '',
    tax_office: 1100,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: 'de2cbfdf-9662-44a4-a4a4-8ad237ae4a3e',
    geoname_code: 'RU.45',
    geoname_id: 529352,
    geoname_name: 'Mariy-El Republic',
    iso_code: 'RU-ME',
    kladr_id: 1200000000000,
    name: 'Марий Эл',
    name_with_type: 'Республика Марий Эл',
    okato: 88000000000,
    oktmo: 88000000,
    postal_code: '',
    tax_office: 1200,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '37a0c60a-9240-48b5-a87f-0d8c86cdb6e1',
    geoname_code: 'RU.46',
    geoname_id: 525369,
    geoname_name: 'Mordoviya Republic',
    iso_code: 'RU-MO',
    kladr_id: 1300000000000,
    name: 'Мордовия',
    name_with_type: 'Республика Мордовия',
    okato: 89000000000,
    oktmo: 89000000,
    postal_code: '',
    tax_office: 1300,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'c225d3db-1db6-4063-ace0-b3fe9ea3805f',
    geoname_code: 'RU.63',
    geoname_id: 2013162,
    geoname_name: 'Sakha',
    iso_code: 'RU-SA',
    kladr_id: 1400000000000,
    name: 'Саха /Якутия/',
    name_with_type: 'Республика Саха /Якутия/',
    okato: 98000000000,
    oktmo: 98000000,
    postal_code: '',
    tax_office: 1400,
    timezone: 'UTC+9',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: 'de459e9c-2933-4923-83d1-9c64cfd7a817',
    geoname_code: 'RU.68',
    geoname_id: 519969,
    geoname_name: 'North Ossetia',
    iso_code: 'RU-SE',
    kladr_id: 1500000000000,
    name: 'Северная Осетия - Алания',
    name_with_type: 'Республика Северная Осетия - Алания',
    okato: 90000000000,
    oktmo: 90000000,
    postal_code: '',
    tax_office: 1500,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '0c089b04-099e-4e0e-955a-6bf1ce525f1a',
    geoname_code: 'RU.73',
    geoname_id: 484048,
    geoname_name: 'Tatarstan Republic',
    iso_code: 'RU-TA',
    kladr_id: 1600000000000,
    name: 'Татарстан',
    name_with_type: 'Республика Татарстан',
    okato: 92000000000,
    oktmo: 92000000,
    postal_code: 420000,
    tax_office: 1600,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '026bc56f-3731-48e9-8245-655331f596c0',
    geoname_code: 'RU.79',
    geoname_id: 1488873,
    geoname_name: 'Republic of Tyva',
    iso_code: 'RU-TY',
    kladr_id: 1700000000000,
    name: 'Тыва',
    name_with_type: 'Республика Тыва',
    okato: 93000000000,
    oktmo: 93000000,
    postal_code: 660000,
    tax_office: 1700,
    timezone: 'UTC+7',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '52618b9c-bcbb-47e7-8957-95c63f0b17cc',
    geoname_code: 'RU.80',
    geoname_id: 479613,
    geoname_name: 'Udmurtiya Republic',
    iso_code: 'RU-UD',
    kladr_id: 1800000000000,
    name: 'Удмуртская',
    name_with_type: 'Удмуртская Респ',
    okato: 94000000000,
    oktmo: 94000000,
    postal_code: '',
    tax_office: 1800,
    timezone: 'UTC+4',
    type: 'Респ',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '8d3f1d35-f0f4-41b5-b5b7-e7cadf3e7bd7',
    geoname_code: 'RU.31',
    geoname_id: 1503834,
    geoname_name: 'Khakasiya Republic',
    iso_code: 'RU-KK',
    kladr_id: 1900000000000,
    name: 'Хакасия',
    name_with_type: 'Республика Хакасия',
    okato: 95000000000,
    oktmo: 95000000,
    postal_code: 655000,
    tax_office: 1900,
    timezone: 'UTC+7',
    type: 'Респ',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: 'de67dc49-b9ba-48a3-a4cc-c2ebfeca6c5e',
    geoname_code: 'RU.12',
    geoname_id: 569665,
    geoname_name: 'Chechnya',
    iso_code: 'RU-CE',
    kladr_id: 2000000000000,
    name: 'Чеченская',
    name_with_type: 'Чеченская Респ',
    okato: 96000000000,
    oktmo: 96000000,
    postal_code: '',
    tax_office: 2000,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '878fc621-3708-46c7-a97f-5a13a4176b3e',
    geoname_code: 'RU.16',
    geoname_id: 567395,
    geoname_name: 'Chuvashia',
    iso_code: 'RU-CU',
    kladr_id: 2100000000000,
    name: 'Чувашская Республика',
    name_with_type: 'Чувашская Республика - Чувашия',
    okato: 97000000000,
    oktmo: 97000000,
    postal_code: '',
    tax_office: 2100,
    timezone: 'UTC+3',
    type: 'Чувашия',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '8276c6a1-1a86-4f0d-8920-aba34d4cc34a',
    geoname_code: 'RU.04',
    geoname_id: 1511732,
    geoname_name: 'Altai Krai',
    iso_code: 'RU-ALT',
    kladr_id: 2200000000000,
    name: 'Алтайский',
    name_with_type: 'Алтайский край',
    okato: '01000000000',
    oktmo: '01000000',
    postal_code: '',
    tax_office: 2200,
    timezone: 'UTC+7',
    type: 'край',
  },
  {
    federal_district: 'Южный',
    fias_id: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
    geoname_code: 'RU.38',
    geoname_id: 542415,
    geoname_name: 'Krasnodarskiy',
    iso_code: 'RU-KDA',
    kladr_id: 2300000000000,
    name: 'Краснодарский',
    name_with_type: 'Краснодарский край',
    okato: '03000000000',
    oktmo: '03000000',
    postal_code: '',
    tax_office: 2300,
    timezone: 'UTC+3',
    type: 'край',
  },
  {
    federal_district: 'Сибирский',
    fias_id: 'db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1',
    geoname_code: 'RU.91',
    geoname_id: 1502020,
    geoname_name: 'Krasnoyarskiy',
    iso_code: 'RU-KYA',
    kladr_id: 2400000000000,
    name: 'Красноярский',
    name_with_type: 'Красноярский край',
    okato: '04000000000',
    oktmo: '04000000',
    postal_code: '',
    tax_office: 2400,
    timezone: 'UTC+7',
    type: 'край',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: '43909681-d6e1-432d-b61f-ddac393cb5da',
    geoname_code: 'RU.59',
    geoname_id: 2017623,
    geoname_name: 'Primorskiy (Maritime) Kray',
    iso_code: 'RU-PRI',
    kladr_id: 2500000000000,
    name: 'Приморский',
    name_with_type: 'Приморский край',
    okato: '05000000000',
    oktmo: '05000000',
    postal_code: '',
    tax_office: 2500,
    timezone: 'UTC+10',
    type: 'край',
  },
  {
    federal_district: 'Северо-Кавказский',
    fias_id: '327a060b-878c-4fb4-8dc4-d5595871a3d8',
    geoname_code: 'RU.70',
    geoname_id: 487839,
    geoname_name: 'Stavropol Kray',
    iso_code: 'RU-STA',
    kladr_id: 2600000000000,
    name: 'Ставропольский',
    name_with_type: 'Ставропольский край',
    okato: '07000000000',
    oktmo: '07000000',
    postal_code: '',
    tax_office: 2600,
    timezone: 'UTC+3',
    type: 'край',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: '7d468b39-1afa-41ec-8c4f-97a8603cb3d4',
    geoname_code: 'RU.30',
    geoname_id: 2022888,
    geoname_name: 'Khabarovsk',
    iso_code: 'RU-KHA',
    kladr_id: 2700000000000,
    name: 'Хабаровский',
    name_with_type: 'Хабаровский край',
    okato: '08000000000',
    oktmo: '08000000',
    postal_code: '',
    tax_office: 2700,
    timezone: 'UTC+10',
    type: 'край',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: '844a80d6-5e31-4017-b422-4d9c01e9942c',
    geoname_code: 'RU.05',
    geoname_id: 2027748,
    geoname_name: 'Amur Oblast',
    iso_code: 'RU-AMU',
    kladr_id: 2800000000000,
    name: 'Амурская',
    name_with_type: 'Амурская обл',
    okato: 10000000000,
    oktmo: 10000000,
    postal_code: 675000,
    tax_office: 2800,
    timezone: 'UTC+9',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '294277aa-e25d-428c-95ad-46719c4ddb44',
    geoname_code: 'RU.06',
    geoname_id: 581043,
    geoname_name: 'Arkhangelskaya',
    iso_code: 'RU-ARK',
    kladr_id: 2900000000000,
    name: 'Архангельская',
    name_with_type: 'Архангельская обл',
    okato: 11000000000,
    oktmo: 11000000,
    postal_code: '',
    tax_office: 2900,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Южный',
    fias_id: '83009239-25cb-4561-af8e-7ee111b1cb73',
    geoname_code: 'RU.07',
    geoname_id: 580491,
    geoname_name: 'Astrakhan',
    iso_code: 'RU-AST',
    kladr_id: 3000000000000,
    name: 'Астраханская',
    name_with_type: 'Астраханская обл',
    okato: 12000000000,
    oktmo: 12000000,
    postal_code: '',
    tax_office: 3000,
    timezone: 'UTC+4',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '639efe9d-3fc8-4438-8e70-ec4f2321f2a7',
    geoname_code: 'RU.09',
    geoname_id: 578071,
    geoname_name: 'Belgorod Oblast',
    iso_code: 'RU-BEL',
    kladr_id: 3100000000000,
    name: 'Белгородская',
    name_with_type: 'Белгородская обл',
    okato: 14000000000,
    oktmo: 14000000,
    postal_code: '',
    tax_office: 3100,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'f5807226-8be0-4ea8-91fc-39d053aec1e2',
    geoname_code: 'RU.10',
    geoname_id: 571473,
    geoname_name: 'Bryansk Oblast',
    iso_code: 'RU-BRY',
    kladr_id: 3200000000000,
    name: 'Брянская',
    name_with_type: 'Брянская обл',
    okato: 15000000000,
    oktmo: 15000000,
    postal_code: '',
    tax_office: 3200,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'b8837188-39ee-4ff9-bc91-fcc9ed451bb3',
    geoname_code: 'RU.83',
    geoname_id: 826294,
    geoname_name: 'Vladimir',
    iso_code: 'RU-VLA',
    kladr_id: 3300000000000,
    name: 'Владимирская',
    name_with_type: 'Владимирская обл',
    okato: 17000000000,
    oktmo: 17000000,
    postal_code: '',
    tax_office: 3300,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Южный',
    fias_id: 'da051ec8-da2e-4a66-b542-473b8d221ab4',
    geoname_code: 'RU.84',
    geoname_id: 472755,
    geoname_name: 'Volgograd Oblast',
    iso_code: 'RU-VGG',
    kladr_id: 3400000000000,
    name: 'Волгоградская',
    name_with_type: 'Волгоградская обл',
    okato: 18000000000,
    oktmo: 18000000,
    postal_code: '',
    tax_office: 3400,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: 'ed36085a-b2f5-454f-b9a9-1c9a678ee618',
    geoname_code: 'RU.85',
    geoname_id: 472454,
    geoname_name: 'Vologda',
    iso_code: 'RU-VLG',
    kladr_id: 3500000000000,
    name: 'Вологодская',
    name_with_type: 'Вологодская обл',
    okato: 19000000000,
    oktmo: 19000000,
    postal_code: '',
    tax_office: 3500,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'b756fe6b-bbd3-44d5-9302-5bfcc740f46e',
    geoname_code: 'RU.86',
    geoname_id: 472039,
    geoname_name: 'Voronezj',
    iso_code: 'RU-VOR',
    kladr_id: 3600000000000,
    name: 'Воронежская',
    name_with_type: 'Воронежская обл',
    okato: 20000000000,
    oktmo: 20000000,
    postal_code: '',
    tax_office: 3600,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '0824434f-4098-4467-af72-d4f702fed335',
    geoname_code: 'RU.21',
    geoname_id: 555235,
    geoname_name: 'Ivanovo',
    iso_code: 'RU-IVA',
    kladr_id: 3700000000000,
    name: 'Ивановская',
    name_with_type: 'Ивановская обл',
    okato: 24000000000,
    oktmo: 24000000,
    postal_code: '',
    tax_office: 3700,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '6466c988-7ce3-45e5-8b97-90ae16cb1249',
    geoname_code: 'RU.20',
    geoname_id: 2023468,
    geoname_name: 'Irkutsk Oblast',
    iso_code: 'RU-IRK',
    kladr_id: 3800000000000,
    name: 'Иркутская',
    name_with_type: 'Иркутская обл',
    okato: 25000000000,
    oktmo: 25000000,
    postal_code: '',
    tax_office: 3800,
    timezone: 'UTC+8',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '90c7181e-724f-41b3-b6c6-bd3ec7ae3f30',
    geoname_code: 'RU.23',
    geoname_id: 554230,
    geoname_name: 'Kaliningrad',
    iso_code: 'RU-KGD',
    kladr_id: 3900000000000,
    name: 'Калининградская',
    name_with_type: 'Калининградская обл',
    okato: 27000000000,
    oktmo: 27000000,
    postal_code: '',
    tax_office: 3900,
    timezone: 'UTC+2',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '18133adf-90c2-438e-88c4-62c41656de70',
    geoname_code: 'RU.25',
    geoname_id: 553899,
    geoname_name: 'Kaluga',
    iso_code: 'RU-KLU',
    kladr_id: 4000000000000,
    name: 'Калужская',
    name_with_type: 'Калужская обл',
    okato: 29000000000,
    oktmo: 29000000,
    postal_code: '',
    tax_office: 4000,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'd02f30fc-83bf-4c0f-ac2b-5729a866a207',
    geoname_code: 'RU.92',
    geoname_id: 2125072,
    geoname_name: 'Kamchatka',
    iso_code: 'RU-KAM',
    kladr_id: 4100000000000,
    name: 'Камчатский',
    name_with_type: 'Камчатский край',
    okato: 30000000000,
    oktmo: 30000000,
    postal_code: '',
    tax_office: 4100,
    timezone: 'UTC+12',
    type: 'край',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '393aeccb-89ef-4a7e-ae42-08d5cebc2e30',
    geoname_code: 'RU.29',
    geoname_id: 1503900,
    geoname_name: 'Kemerovo Oblast',
    iso_code: 'RU-KEM',
    kladr_id: 4200000000000,
    name: 'Кемеровская область - Кузбасс',
    name_with_type: 'Кемеровская область - Кузбасс',
    okato: 32000000000,
    oktmo: 32000000,
    postal_code: '',
    tax_office: 4200,
    timezone: 'UTC+7',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '0b940b96-103f-4248-850c-26b6c7296728',
    geoname_code: 'RU.33',
    geoname_id: 548389,
    geoname_name: 'Kirov',
    iso_code: 'RU-KIR',
    kladr_id: 4300000000000,
    name: 'Кировская',
    name_with_type: 'Кировская обл',
    okato: 33000000000,
    oktmo: 33000000,
    postal_code: 610000,
    tax_office: 4300,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '15784a67-8cea-425b-834a-6afe0e3ed61c',
    geoname_code: 'RU.37',
    geoname_id: 543871,
    geoname_name: 'Kostroma Oblast',
    iso_code: 'RU-KOS',
    kladr_id: 4400000000000,
    name: 'Костромская',
    name_with_type: 'Костромская обл',
    okato: 34000000000,
    oktmo: 34000000,
    postal_code: '',
    tax_office: 4400,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Уральский',
    fias_id: '4a3d970f-520e-46b9-b16c-50d4ca7535a8',
    geoname_code: 'RU.40',
    geoname_id: 1501312,
    geoname_name: 'Kurgan Oblast',
    iso_code: 'RU-KGN',
    kladr_id: 4500000000000,
    name: 'Курганская',
    name_with_type: 'Курганская обл',
    okato: 37000000000,
    oktmo: 37000000,
    postal_code: 641000,
    tax_office: 4500,
    timezone: 'UTC+5',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'ee594d5e-30a9-40dc-b9f2-0add1be44ba1',
    geoname_code: 'RU.41',
    geoname_id: 538555,
    geoname_name: 'Kursk',
    iso_code: 'RU-KRS',
    kladr_id: 4600000000000,
    name: 'Курская',
    name_with_type: 'Курская обл',
    okato: 38000000000,
    oktmo: 38000000,
    postal_code: '',
    tax_office: 4600,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '6d1ebb35-70c6-4129-bd55-da3969658f5d',
    geoname_code: 'RU.42',
    geoname_id: 536199,
    geoname_name: 'Leningradskaya Oblast',
    iso_code: 'RU-LEN',
    kladr_id: 4700000000000,
    name: 'Ленинградская',
    name_with_type: 'Ленинградская обл',
    okato: 41000000000,
    oktmo: 41000000,
    postal_code: '',
    tax_office: 4700,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '1490490e-49c5-421c-9572-5673ba5d80c8',
    geoname_code: 'RU.43',
    geoname_id: 535120,
    geoname_name: 'Lipetsk Oblast',
    iso_code: 'RU-LIP',
    kladr_id: 4800000000000,
    name: 'Липецкая',
    name_with_type: 'Липецкая обл',
    okato: 42000000000,
    oktmo: 42000000,
    postal_code: '',
    tax_office: 4800,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: '9c05e812-8679-4710-b8cb-5e8bd43cdf48',
    geoname_code: 'RU.44',
    geoname_id: 2123627,
    geoname_name: 'Magadan Oblast',
    iso_code: 'RU-MAG',
    kladr_id: 4900000000000,
    name: 'Магаданская',
    name_with_type: 'Магаданская обл',
    okato: 44000000000,
    oktmo: 44000000,
    postal_code: '',
    tax_office: 4900,
    timezone: 'UTC+11',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '29251dcf-00a1-4e34-98d4-5c47484a36d4',
    geoname_code: 'RU.47',
    geoname_id: 524925,
    geoname_name: 'Moscow Oblast',
    iso_code: 'RU-MOS',
    kladr_id: 5000000000000,
    name: 'Московская',
    name_with_type: 'Московская обл',
    okato: 46000000000,
    oktmo: 46000000,
    postal_code: '',
    tax_office: 5000,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '1c727518-c96a-4f34-9ae6-fd510da3be03',
    geoname_code: 'RU.49',
    geoname_id: 524304,
    geoname_name: 'Murmansk',
    iso_code: 'RU-MUR',
    kladr_id: 5100000000000,
    name: 'Мурманская',
    name_with_type: 'Мурманская обл',
    okato: 47000000000,
    oktmo: 47000000,
    postal_code: '',
    tax_office: 5100,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '88cd27e2-6a8a-4421-9718-719a28a0a088',
    geoname_code: 'RU.51',
    geoname_id: 559838,
    geoname_name: 'Nizhny Novgorod Oblast',
    iso_code: 'RU-NIZ',
    kladr_id: 5200000000000,
    name: 'Нижегородская',
    name_with_type: 'Нижегородская обл',
    okato: 22000000000,
    oktmo: 22000000,
    postal_code: '',
    tax_office: 5200,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: 'e5a84b81-8ea1-49e3-b3c4-0528651be129',
    geoname_code: 'RU.52',
    geoname_id: 519324,
    geoname_name: 'Novgorod Oblast',
    iso_code: 'RU-NGR',
    kladr_id: 5300000000000,
    name: 'Новгородская',
    name_with_type: 'Новгородская обл',
    okato: 49000000000,
    oktmo: 49000000,
    postal_code: '',
    tax_office: 5300,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '1ac46b49-3209-4814-b7bf-a509ea1aecd9',
    geoname_code: 'RU.53',
    geoname_id: 1496745,
    geoname_name: 'Novosibirsk Oblast',
    iso_code: 'RU-NVS',
    kladr_id: 5400000000000,
    name: 'Новосибирская',
    name_with_type: 'Новосибирская обл',
    okato: 50000000000,
    oktmo: 50000000,
    postal_code: '',
    tax_office: 5400,
    timezone: 'UTC+7',
    type: 'обл',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '05426864-466d-41a3-82c4-11e61cdc98ce',
    geoname_code: 'RU.54',
    geoname_id: 1496152,
    geoname_name: 'Omsk',
    iso_code: 'RU-OMS',
    kladr_id: 5500000000000,
    name: 'Омская',
    name_with_type: 'Омская обл',
    okato: 52000000000,
    oktmo: 52000000,
    postal_code: '',
    tax_office: 5500,
    timezone: 'UTC+6',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '8bcec9d6-05bc-4e53-b45c-ba0c6f3a5c44',
    geoname_code: 'RU.55',
    geoname_id: 515001,
    geoname_name: 'Orenburg Oblast',
    iso_code: 'RU-ORE',
    kladr_id: 5600000000000,
    name: 'Оренбургская',
    name_with_type: 'Оренбургская обл',
    okato: 53000000000,
    oktmo: 53000000,
    postal_code: 460000,
    tax_office: 5600,
    timezone: 'UTC+5',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '5e465691-de23-4c4e-9f46-f35a125b5970',
    geoname_code: 'RU.56',
    geoname_id: 514801,
    geoname_name: 'Orel Oblast',
    iso_code: 'RU-ORL',
    kladr_id: 5700000000000,
    name: 'Орловская',
    name_with_type: 'Орловская обл',
    okato: 54000000000,
    oktmo: 54000000,
    postal_code: '',
    tax_office: 5700,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: 'c99e7924-0428-4107-a302-4fd7c0cca3ff',
    geoname_code: 'RU.57',
    geoname_id: 511555,
    geoname_name: 'Penza',
    iso_code: 'RU-PNZ',
    kladr_id: 5800000000000,
    name: 'Пензенская',
    name_with_type: 'Пензенская обл',
    okato: 56000000000,
    oktmo: 56000000,
    postal_code: '',
    tax_office: 5800,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: '4f8b1a21-e4bb-422f-9087-d3cbf4bebc14',
    geoname_code: 'RU.90',
    geoname_id: 511180,
    geoname_name: 'Perm',
    iso_code: 'RU-PER',
    kladr_id: 5900000000000,
    name: 'Пермский',
    name_with_type: 'Пермский край',
    okato: 57000000000,
    oktmo: 57000000,
    postal_code: '',
    tax_office: 5900,
    timezone: 'UTC+5',
    type: 'край',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: 'f6e148a1-c9d0-4141-a608-93e3bd95e6c4',
    geoname_code: 'RU.60',
    geoname_id: 504338,
    geoname_name: 'Pskov Oblast',
    iso_code: 'RU-PSK',
    kladr_id: 6000000000000,
    name: 'Псковская',
    name_with_type: 'Псковская обл',
    okato: 58000000000,
    oktmo: 58000000,
    postal_code: '',
    tax_office: 6000,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Южный',
    fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
    geoname_code: 'RU.61',
    geoname_id: 501165,
    geoname_name: 'Rostov',
    iso_code: 'RU-ROS',
    kladr_id: 6100000000000,
    name: 'Ростовская',
    name_with_type: 'Ростовская обл',
    okato: 60000000000,
    oktmo: 60000000,
    postal_code: '',
    tax_office: 6100,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '963073ee-4dfc-48bd-9a70-d2dfc6bd1f31',
    geoname_code: 'RU.62',
    geoname_id: 500059,
    geoname_name: 'Ryazan Oblast',
    iso_code: 'RU-RYA',
    kladr_id: 6200000000000,
    name: 'Рязанская',
    name_with_type: 'Рязанская обл',
    okato: 61000000000,
    oktmo: 61000000,
    postal_code: 390000,
    tax_office: 6200,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: 'df3d7359-afa9-4aaa-8ff9-197e73906b1c',
    geoname_code: 'RU.65',
    geoname_id: 499068,
    geoname_name: 'Samara Oblast',
    iso_code: 'RU-SAM',
    kladr_id: 6300000000000,
    name: 'Самарская',
    name_with_type: 'Самарская обл',
    okato: 36000000000,
    oktmo: 36000000,
    postal_code: '',
    tax_office: 6300,
    timezone: 'UTC+4',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: 'df594e0e-a935-4664-9d26-0bae13f904fe',
    geoname_code: 'RU.67',
    geoname_id: 498671,
    geoname_name: 'Saratovskaya Oblast',
    iso_code: 'RU-SAR',
    kladr_id: 6400000000000,
    name: 'Саратовская',
    name_with_type: 'Саратовская обл',
    okato: 63000000000,
    oktmo: 63000000,
    postal_code: 410000,
    tax_office: 6400,
    timezone: 'UTC+4',
    type: 'обл',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'aea6280f-4648-460f-b8be-c2bc18923191',
    geoname_code: 'RU.64',
    geoname_id: 2121529,
    geoname_name: 'Sakhalin Oblast',
    iso_code: 'RU-SAK',
    kladr_id: 6500000000000,
    name: 'Сахалинская',
    name_with_type: 'Сахалинская обл',
    okato: 64000000000,
    oktmo: 64000000,
    postal_code: 693000,
    tax_office: 6500,
    timezone: 'UTC+11',
    type: 'обл',
  },
  {
    federal_district: 'Уральский',
    fias_id: '92b30014-4d52-4e2e-892d-928142b924bf',
    geoname_code: 'RU.71',
    geoname_id: 1490542,
    geoname_name: 'Sverdlovsk',
    iso_code: 'RU-SVE',
    kladr_id: 6600000000000,
    name: 'Свердловская',
    name_with_type: 'Свердловская обл',
    okato: 65000000000,
    oktmo: 65000000,
    postal_code: 620000,
    tax_office: 6600,
    timezone: 'UTC+5',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'e8502180-6d08-431b-83ea-c7038f0df905',
    geoname_code: 'RU.69',
    geoname_id: 491684,
    geoname_name: 'Smolensk',
    iso_code: 'RU-SMO',
    kladr_id: 6700000000000,
    name: 'Смоленская',
    name_with_type: 'Смоленская обл',
    okato: 66000000000,
    oktmo: 66000000,
    postal_code: '',
    tax_office: 6700,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'a9a71961-9363-44ba-91b5-ddf0463aebc2',
    geoname_code: 'RU.72',
    geoname_id: 484638,
    geoname_name: 'Tambov',
    iso_code: 'RU-TAM',
    kladr_id: 6800000000000,
    name: 'Тамбовская',
    name_with_type: 'Тамбовская обл',
    okato: 68000000000,
    oktmo: 68000000,
    postal_code: 392000,
    tax_office: 6800,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '61723327-1c20-42fe-8dfa-402638d9b396',
    geoname_code: 'RU.77',
    geoname_id: 480041,
    geoname_name: 'Tver Oblast',
    iso_code: 'RU-TVE',
    kladr_id: 6900000000000,
    name: 'Тверская',
    name_with_type: 'Тверская обл',
    okato: 28000000000,
    oktmo: 28000000,
    postal_code: '',
    tax_office: 6900,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Сибирский',
    fias_id: '889b1f3a-98aa-40fc-9d3d-0f41192758ab',
    geoname_code: 'RU.75',
    geoname_id: 1489421,
    geoname_name: 'Tomsk Oblast',
    iso_code: 'RU-TOM',
    kladr_id: 7000000000000,
    name: 'Томская',
    name_with_type: 'Томская обл',
    okato: 69000000000,
    oktmo: 69000000,
    postal_code: '',
    tax_office: 7000,
    timezone: 'UTC+7',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'd028ec4f-f6da-4843-ada6-b68b3e0efa3d',
    geoname_code: 'RU.76',
    geoname_id: 480508,
    geoname_name: 'Tula',
    iso_code: 'RU-TUL',
    kladr_id: 7100000000000,
    name: 'Тульская',
    name_with_type: 'Тульская обл',
    okato: 70000000000,
    oktmo: 70000000,
    postal_code: '',
    tax_office: 7100,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Уральский',
    fias_id: '54049357-326d-4b8f-b224-3c6dc25d6dd3',
    geoname_code: 'RU.78',
    geoname_id: 1488747,
    geoname_name: 'Tyumen Oblast',
    iso_code: 'RU-TYU',
    kladr_id: 7200000000000,
    name: 'Тюменская',
    name_with_type: 'Тюменская обл',
    okato: 71000000000,
    oktmo: 71000000,
    postal_code: '',
    tax_office: 7200,
    timezone: 'UTC+5',
    type: 'обл',
  },
  {
    federal_district: 'Приволжский',
    fias_id: 'fee76045-fe22-43a4-ad58-ad99e903bd58',
    geoname_code: 'RU.81',
    geoname_id: 479119,
    geoname_name: 'Ulyanovsk',
    iso_code: 'RU-ULY',
    kladr_id: 7300000000000,
    name: 'Ульяновская',
    name_with_type: 'Ульяновская обл',
    okato: 73000000000,
    oktmo: 73000000,
    postal_code: 433000,
    tax_office: 7300,
    timezone: 'UTC+4',
    type: 'обл',
  },
  {
    federal_district: 'Уральский',
    fias_id: '27eb7c10-a234-44da-a59c-8b1f864966de',
    geoname_code: 'RU.13',
    geoname_id: 1508290,
    geoname_name: 'Chelyabinsk',
    iso_code: 'RU-CHE',
    kladr_id: 7400000000000,
    name: 'Челябинская',
    name_with_type: 'Челябинская обл',
    okato: 75000000000,
    oktmo: 75000000,
    postal_code: 454000,
    tax_office: 7400,
    timezone: 'UTC+5',
    type: 'обл',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'b6ba5716-eb48-401b-8443-b197c9578734',
    geoname_code: 'RU.93',
    geoname_id: 7779061,
    geoname_name: 'Transbaikal Territory',
    iso_code: 'RU-ZAB',
    kladr_id: 7500000000000,
    name: 'Забайкальский',
    name_with_type: 'Забайкальский край',
    okato: 76000000000,
    oktmo: 76000000,
    postal_code: '',
    tax_office: 7500,
    timezone: 'UTC+9',
    type: 'край',
  },
  {
    federal_district: 'Центральный',
    fias_id: 'a84b2ef4-db03-474b-b552-6229e801ae9b',
    geoname_code: 'RU.88',
    geoname_id: 468898,
    geoname_name: 'Jaroslavl',
    iso_code: 'RU-YAR',
    kladr_id: 7600000000000,
    name: 'Ярославская',
    name_with_type: 'Ярославская обл',
    okato: 78000000000,
    oktmo: 78000000,
    postal_code: '',
    tax_office: 7600,
    timezone: 'UTC+3',
    type: 'обл',
  },
  {
    federal_district: 'Центральный',
    fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    geoname_code: 'RU.48',
    geoname_id: 524894,
    geoname_name: 'Moscow',
    iso_code: 'RU-MOW',
    kladr_id: 7700000000000,
    name: 'Москва',
    name_with_type: 'г Москва',
    okato: 45000000000,
    oktmo: 45000000,
    postal_code: 101000,
    tax_office: 7700,
    timezone: 'UTC+3',
    type: 'г',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    geoname_code: 'RU.66',
    geoname_id: 536203,
    geoname_name: 'St.-Petersburg',
    iso_code: 'RU-SPE',
    kladr_id: 7800000000000,
    name: 'Санкт-Петербург',
    name_with_type: 'г Санкт-Петербург',
    okato: 40000000000,
    oktmo: 40000000,
    postal_code: 190000,
    tax_office: 7800,
    timezone: 'UTC+3',
    type: 'г',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: '1b507b09-48c9-434f-bf6f-65066211c73e',
    geoname_code: 'RU.89',
    geoname_id: 2026639,
    geoname_name: 'Jewish Autonomous Oblast',
    iso_code: 'RU-YEV',
    kladr_id: 7900000000000,
    name: 'Еврейская',
    name_with_type: 'Еврейская Аобл',
    okato: 99000000000,
    oktmo: 99000000,
    postal_code: '',
    tax_office: 7900,
    timezone: 'UTC+10',
    type: 'Аобл',
  },
  {
    federal_district: 'Северо-Западный',
    fias_id: '89db3198-6803-4106-9463-cbf781eff0b8',
    geoname_code: 'RU.50',
    geoname_id: 522652,
    geoname_name: 'Nenets',
    iso_code: 'RU-NEN',
    kladr_id: 8300000000000,
    name: 'Ненецкий',
    name_with_type: 'Ненецкий АО',
    okato: 11100000000,
    oktmo: 11800000,
    postal_code: 166000,
    tax_office: 2983,
    timezone: 'UTC+3',
    type: 'АО',
  },
  {
    federal_district: 'Уральский',
    fias_id: 'd66e5325-3a25-4d29-ba86-4ca351d9704b',
    geoname_code: 'RU.32',
    geoname_id: 1503773,
    geoname_name: 'Khanty-Mansia',
    iso_code: 'RU-KHM',
    kladr_id: 8600000000000,
    name: 'Ханты-Мансийский Автономный округ - Югра',
    name_with_type: 'Ханты-Мансийский Автономный округ - Югра',
    okato: 71100000000,
    oktmo: 71800000,
    postal_code: '',
    tax_office: 8600,
    timezone: 'UTC+5',
    type: 'АО',
  },
  {
    federal_district: 'Дальневосточный',
    fias_id: 'f136159b-404a-4f1f-8d8d-d169e1374d5c',
    geoname_code: 'RU.15',
    geoname_id: 2126099,
    geoname_name: 'Chukotka',
    iso_code: 'RU-CHU',
    kladr_id: 8700000000000,
    name: 'Чукотский',
    name_with_type: 'Чукотский АО',
    okato: 77000000000,
    oktmo: 77000000,
    postal_code: '',
    tax_office: 8700,
    timezone: 'UTC+12',
    type: 'АО',
  },
  {
    federal_district: 'Уральский',
    fias_id: '826fa834-3ee8-404f-bdbc-13a5221cfb6e',
    geoname_code: 'RU.87',
    geoname_id: 1486462,
    geoname_name: 'Yamalo-Nenets',
    iso_code: 'RU-YAN',
    kladr_id: 8900000000000,
    name: 'Ямало-Ненецкий',
    name_with_type: 'Ямало-Ненецкий АО',
    okato: 71140000000,
    oktmo: 71900000,
    postal_code: 629000,
    tax_office: 8900,
    timezone: 'UTC+5',
    type: 'АО',
  },
  {
    federal_district: 'Южный',
    fias_id: 'bd8e6511-e4b9-4841-90de-6bbc231a789e',
    geoname_code: 'UA.11',
    geoname_id: 703883,
    geoname_name: 'Crimea',
    iso_code: 'UA-43',
    kladr_id: 9100000000000,
    name: 'Крым',
    name_with_type: 'Республика Крым',
    okato: 35000000000,
    oktmo: 35000000,
    postal_code: '',
    tax_office: 9100,
    timezone: 'UTC+3',
    type: 'Респ',
  },
  {
    federal_district: 'Южный',
    fias_id: '6fdecb78-893a-4e3f-a5ba-aa062459463b',
    geoname_code: 'UA.20',
    geoname_id: 694422,
    geoname_name: 'Sevastopol City',
    iso_code: 'UA-40',
    kladr_id: 9200000000000,
    name: 'Севастополь',
    name_with_type: 'г Севастополь',
    okato: 67000000000,
    oktmo: 67000000,
    postal_code: 299700,
    tax_office: 9200,
    timezone: 'UTC+3',
    type: 'г',
  },
  {
    federal_district: '',
    fias_id: '63ed1a35-4be6-4564-a1ec-0c51f7383314',
    geoname_code: 'KZ.08',
    geoname_id: 1538316,
    geoname_name: 'Baikonur',
    iso_code: 'KZ-BAY',
    kladr_id: 9900000000000,
    name: 'Байконур',
    name_with_type: 'г Байконур',
    okato: 55000000000,
    oktmo: 55000000,
    postal_code: 468320,
    tax_office: 9900,
    timezone: 'UTC+5',
    type: 'г',
  },
];
