import {
  // BaseQueryFn,
  // FetchArgs,
  // FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';

import { RootState } from '../../store';
import {
  IAddRequestAdBody,
  IGetRequestAdsParams,
  IGetRequestAdsResponse,
  IRequestAd,
} from './interface';
import { TEditRequestAdBody } from './types';

const baseUrl = process.env.REACT_APP_API_URL as string;

const baseQuery = fetchBaseQuery({
  baseUrl,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const staggeredBaseQuery = retry(baseQuery, {
  maxRetries: 3,
});

export const requestAdsApi = createApi({
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    addRequestAd: builder.mutation<IRequestAd, IAddRequestAdBody>({
      invalidatesTags: ['RequestAds'],
      query: (data) => ({
        body: data,
        method: 'POST',
        url: 'api/request_announcements',
      }),
    }),

    editRequestAd: builder.mutation<IRequestAd, { data: TEditRequestAdBody; id: number }>({
      invalidatesTags: ['RequestAds'],
      query: ({ data, id }) => ({
        body: data,
        method: 'PATCH',
        url: `api/request_announcements/${id}`,
      }),
    }),

    getRequestAds: builder.query<IGetRequestAdsResponse, IGetRequestAdsParams>({
      // extraOptions: { maxRetries: 5 },
      providesTags: ['RequestAds'],
      query: (params) => ({
        params,
        url: 'api/request_announcements',
      }),
    }),

    removeRequestAd: builder.mutation<IRequestAd, { id: number }>({
      invalidatesTags: ['RequestAds'],
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/api/request_announcements/${id}`,
      }),
    }),
  }),
  reducerPath: 'requestAdsApi',

  tagTypes: ['RequestAds'],
});

export const {
  useAddRequestAdMutation,
  useEditRequestAdMutation,
  useGetRequestAdsQuery,
  useRemoveRequestAdMutation,
} = requestAdsApi;
