import React from 'react';
import Marquee from 'react-double-marquee';

import telegramIcon from '../../assets/telegram_icon.png';
import { StyledContainer } from '../../styles/common-styled-components/styles';
import * as S from './styles';

const Footer = () => {
  return (
    <S.Footer>
      <StyledContainer>
        <S.FooterInner>
          <S.TgLink to='https://t.me/+gyyKrGBEK7VhZmZi' target='_blank'>
            <div>
              <img src={telegramIcon} />
            </div>
            <S.TgLinkTextWrapper>
              <Marquee delay={1000}>Наш Telegram канал: ЗАЛОГИ PRO: Food & Farming</Marquee>
            </S.TgLinkTextWrapper>
          </S.TgLink>
        </S.FooterInner>
      </StyledContainer>
    </S.Footer>
  );
};

export default Footer;
