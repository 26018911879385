/* eslint-disable no-console */
import React from 'react';
import ContentLoader from 'react-content-loader';

import AdsPages from '../ads-pages';
import ErrorFetch from '../error-handling';
import NotificationNullAds from '../notification-null-ads';
import RequestAdCard from '../request-ad-card';
import RequestAdsCardListProps from './interface';
import * as S from './styles';

const RequestAdsCardList = ({
  data,
  error,
  isError,
  isFetching,
  isLoading,
  isSuccess,
  limit,
  page,
  setPage,
}: RequestAdsCardListProps) => {
  const isEmptyList = !isLoading && !data?.totalCount;

  if (isLoading) {
    return (
      <S.SkeletonWrapper>
        {Array.from({ length: 8 }, (_v, k) => (
          <ContentLoader
            key={k}
            height='100%'
            width='100%'
            viewBox='0 0 320 300'
            speed={2}
            backgroundColor='#d8d5d5'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='6' ry='6' width='60%' height='30' />
            <rect x='0' y='40' rx='6' ry='6' width='100%' height='150' />
            <rect x='45' y='200' rx='6' ry='6' width='60%' height='50' />
          </ContentLoader>
        ))}
      </S.SkeletonWrapper>
    );
  }

  if (isError) {
    return <ErrorFetch error={error} />;
  }

  if (isSuccess) {
    return (
      <>
        {isEmptyList ? (
          <NotificationNullAds
            title='Поиск не дал результатов'
            description='Попробуйте изменить критерии поиска или продолжить поиск позже.'
          />
        ) : (
          <>
            <S.ListRequestAnnouncementWrapper>
              {data &&
                data.listRequestAnnouncement.map((ad) => <RequestAdCard key={ad.id} {...ad} />)}
            </S.ListRequestAnnouncementWrapper>
            <AdsPages
              limit={limit}
              totalCount={data?.totalCount || 0}
              pageState={page}
              setPageState={setPage}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </>
        )}
      </>
    );
  }

  return null;
};

export default RequestAdsCardList;
