import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { device } from '../../utils/consts';

export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 5px 0;
  inset: 0;
  width: 100%;
  box-shadow: 0px -1px 5px 1px rgba(34, 60, 80, 0.2);
  height: 40px;
  z-index: 1;
`;

export const FooterInner = styled.div`
  display: flex;
  justify-content: center;
`;

export const TgLink = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const TgLinkTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  white-space: nowrap;

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptopL} {
    width: 350px;
  }
`;
