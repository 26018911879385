import React, { useState } from 'react';

import { rents } from '../../../../utils/consts';
import * as S from './styles';
import RentFilterTypeProp from './types';

const RentFilter = ({ register }: RentFilterTypeProp) => {
  const [rentState, setRentState] = useState<string | undefined>(undefined);

  const onOptionChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const { target } = e;

    const value = (target as HTMLInputElement).value;

    if (value === rentState) {
      setRentState(undefined);
    } else {
      setRentState(value);
    }
  };

  return (
    <S.RentInputsBlock>
      {rents.map((rent) => (
        <S.RentInputWrapper key={rent.title}>
          <S.RentInputLabel>
            <input
              type='radio'
              value={rent.value}
              checked={rentState === rent.value}
              {...register('isRent', {
                required: false,
              })}
              onClick={(e) => onOptionChange(e)}
            />
            <span>{rent.title}</span>
          </S.RentInputLabel>
        </S.RentInputWrapper>
      ))}
    </S.RentInputsBlock>
  );
};

export default RentFilter;
