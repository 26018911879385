import React from 'react';

import ads from '../../assets/ads.webp';
import analytics from '../../assets/analytics.jpeg';
import betaIcon from '../../assets/beta.png';
import calculator from '../../assets/calculator.png';
import news from '../../assets/news.jpeg';
// import deals from '../../assets/pac2020.jpg';
import place from '../../assets/place.jpeg';
import requestAd from '../../assets/request-announcement.jpeg';
// import stat from '../../assets/stat.jpg';
import HomeBlockLink from '../../components/home-block-link';
import { useGetAdsCountQuery } from '../../redux/services/ads/adsApi';
import { StyledContainer, StyledSection } from '../../styles/common-styled-components/styles';
import {
  ADS_ROUTE,
  ANALYTICS_ROUTE,
  CALCULATOR_ROUTE,
  NEWS_ROUTE,
  OTHER_ROUTE,
  REQUEST_ADS_ROUTE,
  // pages,
} from '../../utils/consts';
import { replaceValueWithSpaces } from '../../utils/funcs/replace-value';
import { MyRound10 } from '../../utils/funcs/round-10';
import * as S from './styles';

const Home = () => {
  const { data, isSuccess } = useGetAdsCountQuery();

  return (
    <StyledSection>
      <StyledContainer>
        <S.HomeBlockList>
          {/* {pages.map((page) => (
            <HomeBlockLink
              key={page.title}
              imgUrl={page.img}
              title={page.title}
              route={page.route}
            />
          ))} */}
          <HomeBlockLink imgUrl={ads} title='Объявления' route={ADS_ROUTE}>
            {isSuccess && data.count > 0 && (
              <p>Более {replaceValueWithSpaces(MyRound10(data.count).toString())} объявлений</p>
            )}
          </HomeBlockLink>
          <HomeBlockLink
            imgUrl={calculator}
            title='Калькулятор cтоимости сельхозугодий'
            route={CALCULATOR_ROUTE}
          >
            <S.CalculatorTitleIcon src={betaIcon} width={40} />
          </HomeBlockLink>
          <HomeBlockLink
            imgUrl={requestAd}
            title='Запросы на покупку земельных участков'
            route={REQUEST_ADS_ROUTE}
          />
          {/* {isAuth && (
            <>
              <HomeBlockLink
                imgUrl={requestAd}
                title='Запросы на покупку ЗУ'
                route={REQUEST_ADS_ROUTE}
              />
            </>
          )} */}
          {/* <HomeBlockLink imgUrl={stat} title='Статистика' route='/'>
            {isSuccess && data.count > 0 && <p>Более {data.count} объявлений</p>}
          </HomeBlockLink> */}
          <HomeBlockLink imgUrl={analytics} title='Аналитика' route={ANALYTICS_ROUTE} />
          {/* <HomeBlockLink imgUrl={deals} title='Сделки слияния и поглощения' route='/' /> */}
          <HomeBlockLink imgUrl={news} title='Новости' route={NEWS_ROUTE} />
          <HomeBlockLink imgUrl={place} title='Полезные ресурсы ' route={OTHER_ROUTE} />
        </S.HomeBlockList>
      </StyledContainer>
    </StyledSection>
  );
};

export default Home;
