/* eslint-disable camelcase */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../../store';
import {
  Ad,
  AdParams,
  AddToFavoritiesAdsBodyType,
  AdsCountResponse,
  AdsResponse,
  SetStatusBodyType,
  ToggleAdCheckedBodyType,
} from './interface';

const baseUrl = process.env.REACT_APP_API_URL as string;

const baseQuery = fetchBaseQuery({
  baseUrl,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const adsApi = createApi({
  baseQuery,

  endpoints: (builder) => ({
    addAd: builder.mutation<Ad, FormData>({
      invalidatesTags: ['Ads', 'Ads_count'],
      query: (data) => ({
        body: data,

        method: 'POST',
        url: '/api/announcements/add_one',
      }),
    }),

    addToFavoritiesAds: builder.mutation<
      { announcementId: number; userId: number },
      AddToFavoritiesAdsBodyType
    >({
      invalidatesTags: ['Ads_favorities'],
      query: ({ announcementId, userId }) => ({
        body: { announcementId, userId },

        method: 'POST',
        url: '/api/announcements/favorities/add',
      }),
    }),

    editAd: builder.mutation<
      Ad,
      { data: FormData; id: number | undefined; isRemoveInitImages: boolean }
    >({
      invalidatesTags: ['Ads', 'Ads_count'],
      query: ({ data, id, isRemoveInitImages }) => ({
        body: data,

        method: 'PATCH',
        params: {
          isRemoveInitImages,
        },
        url: `/api/announcements/${id}`,
      }),
    }),

    getAdById: builder.query<Ad, number | undefined>({
      providesTags: ['Ads'],
      query: (id) => `api/announcements/${id}`,
    }),

    getAds: builder.query<AdsResponse, AdParams>({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      providesTags: (result, error, { provideTag }): any => [provideTag], // TODO: типизировать
      query: ({
        address,
        areaFrom,
        areaTo,
        areaUnit,
        dateRange,
        domain,
        geoBounds,
        isRent,
        keyword,
        landCategory,
        landUse,
        limit,
        page,
        priceFrom,
        priceTo,
        provideTag,
        sorting,
        status,
        unitPrice,
        userId,
      }) => ({
        params: {
          address: address?.length ? encodeURIComponent(address.join(',')) : undefined,
          area_from: areaFrom,
          area_to: areaTo,
          areaUnit,
          date_range: dateRange,
          domain: domain?.length ? encodeURIComponent(domain.join(',')) : undefined,
          geoBounds,
          is_rent: isRent,
          keyword: keyword ? encodeURIComponent(keyword) : undefined,
          land_category: landCategory?.length
            ? encodeURIComponent(landCategory.join(','))
            : undefined,
          land_use: landUse?.length ? encodeURIComponent(landUse.join(',')) : undefined,
          limit,
          page,
          price_from: priceFrom,
          price_to: priceTo,
          provideTag,
          sorting: JSON.stringify(sorting),
          status,
          unitPrice,
          userId,
        },
        url: 'api/announcements',
      }),
    }),

    getAdsCount: builder.query<AdsCountResponse, void>({
      providesTags: ['Ads_count'],
      query: () => 'api/announcements/count',
    }),

    getFavoritiesAds: builder.query<AdsResponse, { userId: number }>({
      providesTags: ['Ads_favorities'],
      query: ({ userId }) => ({
        params: { userId },
        url: 'api/announcements/favorities',
      }),
    }),

    matchFavoriteAnnouncement: builder.query<
      { isFavorite: boolean },
      { userId: number; announcementId: number }
    >({
      providesTags: ['Ads_favorities'],
      query: ({ announcementId, userId }) => ({
        params: { announcementId, userId },
        url: 'api/announcements/favorities/match',
      }),
    }),

    removeAd: builder.mutation({
      invalidatesTags: ['Ads', 'Ads_count'],
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/api/announcements/${id}`,
      }),
    }),

    removeFromFavoritiesAds: builder.mutation<
      { announcementId: number; userId: number },
      AddToFavoritiesAdsBodyType
    >({
      invalidatesTags: ['Ads_favorities'],
      query: ({ announcementId, userId }) => ({
        body: { announcementId, userId },
        method: 'DELETE',
        url: '/api/announcements/favorities/remove',
      }),
    }),

    setStatus: builder.mutation<{ id: number; status: boolean }, SetStatusBodyType>({
      invalidatesTags: ['Ads'],
      query: ({ id, status }) => ({
        body: { id, status },
        method: 'PATCH',
        url: `api/announcements/${id}/status`,
      }),
    }),

    toggleChecked: builder.mutation<{ id: number; is_checked: boolean }, ToggleAdCheckedBodyType>({
      invalidatesTags: ['Ads'],
      query: ({ id, isChecked }) => ({
        body: { id, isChecked },
        method: 'PATCH',
        url: `api/announcements/${id}/checked`,
      }),
    }),
  }),

  reducerPath: 'adsApi',

  tagTypes: ['Ads', 'Ads_map', 'Ads_count', 'Ads_favorities'],
});

export const {
  useAddAdMutation,
  useAddToFavoritiesAdsMutation,
  useEditAdMutation,
  useGetAdByIdQuery,
  useGetAdsCountQuery,
  useGetAdsQuery,
  useGetFavoritiesAdsQuery,
  useMatchFavoriteAnnouncementQuery,
  useRemoveAdMutation,
  useRemoveFromFavoritiesAdsMutation,
  useSetStatusMutation,
  useToggleCheckedMutation,
} = adsApi;
