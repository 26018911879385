export const regionsPriceRange = [
  {
    id: 2200000000000,
    max: 30000,
    min: 18000,
  },
  {
    id: 2800000000000,
    max: 31000,
    min: 18000,
  },
  {
    id: 3000000000000,
    max: 65000,
    min: 38000,
  },
  {
    id: 3100000000000,
    max: 198000,
    min: 117000,
  },
  {
    id: 3200000000000,
    max: 55000,
    min: 32000,
  },
  {
    id: 3300000000000,
    max: 54000,
    min: 32000,
  },
  {
    id: 3400000000000,
    max: 42000,
    min: 25000,
  },
  {
    id: 3500000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 3600000000000,
    max: 178000,
    min: 105000,
  },
  {
    id: 7500000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 3700000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 3800000000000,
    max: 29000,
    min: 17000,
  },
  {
    id: 700000000000,
    max: 168000,
    min: 99000,
  },
  {
    id: 3900000000000,
    max: 71000,
    min: 42000,
  },
  {
    id: 4000000000000,
    max: 35000,
    min: 20000,
  },
  {
    id: 900000000000,
    max: 226000,
    min: 134000,
  },
  {
    id: 4200000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 4300000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 4400000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 2300000000000,
    max: 363000,
    min: 215000,
  },
  {
    id: 2400000000000,
    max: 26000,
    min: 15000,
  },
  {
    id: 4500000000000,
    max: 34000,
    min: 20000,
  },
  {
    id: 4600000000000,
    max: 190000,
    min: 113000,
  },
  {
    id: 4700000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 4800000000000,
    max: 181000,
    min: 107000,
  },
  {
    id: 5000000000000,
    max: 199000,
    min: 125000,
  },
  {
    id: 5200000000000,
    max: 28000,
    min: 16000,
  },
  {
    id: 5300000000000,
    max: 35000,
    min: 21000,
  },
  {
    id: 5400000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 5500000000000,
    max: 34000,
    min: 20000,
  },
  {
    id: 5600000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 5700000000000,
    max: 187000,
    min: 111000,
  },
  {
    id: 5800000000000,
    max: 69000,
    min: 41000,
  },
  {
    id: 5900000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 2500000000000,
    max: 60000,
    min: 36000,
  },
  {
    id: 6000000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 100000000000,
    max: 318000,
    min: 188000,
  },
  {
    id: 200000000000,
    max: 70000,
    min: 47000,
  },
  {
    id: 500000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 600000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 800000000000,
    max: 70000,
    min: 30000,
  },
  {
    id: 1100000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 9100000000000,
    max: 100000,
    min: 70000,
  },
  {
    id: 1200000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 1300000000000,
    max: 41000,
    min: 24000,
  },
  {
    id: 1500000000000,
    max: 179000,
    min: 106000,
  },
  {
    id: 1600000000000,
    max: 75000,
    min: 44000,
  },
  {
    id: 6100000000000,
    max: 201000,
    min: 120000,
  },
  {
    id: 6200000000000,
    max: 65000,
    min: 38000,
  },
  {
    id: 6300000000000,
    max: 50000,
    min: 30000,
  },
  {
    id: 6400000000000,
    max: 70000,
    min: 39000,
  },
  {
    id: 6600000000000,
    max: 49000,
    min: 29000,
  },
  {
    id: 6700000000000,
    max: 32000,
    min: 19000,
  },
  {
    id: 2600000000000,
    max: 194000,
    min: 116000,
  },
  {
    id: 6800000000000,
    max: 193000,
    min: 129000,
  },
  {
    id: 6900000000000,
    max: 36000,
    min: 21000,
  },
  {
    id: 7100000000000,
    max: 94000,
    min: 55000,
  },
  {
    id: 7200000000000,
    max: 37000,
    min: 22000,
  },
  {
    id: 1800000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 7300000000000,
    max: 36000,
    min: 21000,
  },
  {
    id: 2700000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 7400000000000,
    max: 36000,
    min: 21000,
  },
  {
    id: 2000000000000,
    max: undefined,
    min: undefined,
  },
  {
    id: 2100000000000,
    max: 46000,
    min: 27000,
  },
  {
    id: 7600000000000,
    max: 39000,
    min: 26000,
  },
];
