import type { DateRange } from 'rsuite/esm/DateRangePicker/types';

import { DateRangePicker, useMediaQuery } from 'rsuite';

import { useAppSelector } from '../../../../redux/hooks';
import { selectFilterAds } from '../../../../redux/slices/filtersAdsSlice';
import { device } from '../../../../utils/consts';
import DatePublishedFilterTypeProp from './types';

import 'rsuite/DateRangePicker/styles/index.css';

const DatePublishedFilter = ({ setValue }: DatePublishedFilterTypeProp) => {
  const [isTablet] = useMediaQuery([device.tablet]);

  const { dateRange } = useAppSelector(selectFilterAds);

  const onChangeHandler = (dates: DateRange | null) => {
    if (dates) {
      if (dates[0].getTime() === dates[1].getTime()) {
        const formateDate = new Date(
          new Date(new Date(dates[0]).setDate(new Date(dates[0]).getDate() + 1)).setUTCHours(
            0,
            0,
            0,
            0,
          ),
        ).toISOString();

        setValue('dateRange', formateDate);
      } else {
        const formateDates = dates.map((date) =>
          new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        );

        setValue('dateRange', formateDates);
      }
    }
  };

  const onCleanHandler = () => {
    setValue('dateRange', undefined);
  };

  return (
    <DateRangePicker
      onChange={onChangeHandler}
      character=' - '
      format='dd/MM/yyyy'
      placeholder='дд/мм/гггг - дд/мм/гггг'
      showHeader={false}
      editable={false}
      onClean={onCleanHandler}
      isoWeek
      showOneCalendar={!isTablet ? true : false}
      placement='auto'
      preventOverflow
      defaultValue={dateRange ? [new Date(dateRange[0]), new Date(dateRange[1])] : undefined}
    />
  );
};

export default DatePublishedFilter;
